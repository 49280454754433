import { render } from "./newestDetail.vue?vue&type=template&id=1c2a99a5&scoped=true"
import script from "./newestDetail.vue?vue&type=script&lang=js"
export * from "./newestDetail.vue?vue&type=script&lang=js"

import "./newestDetail.vue?vue&type=style&index=0&id=1c2a99a5&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-1c2a99a5"
/* hot reload */
if (module.hot) {
  script.__hmrId = "1c2a99a5"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1c2a99a5', script)) {
    api.reload('1c2a99a5', script)
  }
  
  module.hot.accept("./newestDetail.vue?vue&type=template&id=1c2a99a5&scoped=true", () => {
    api.rerender('1c2a99a5', render)
  })

}

script.__file = "src/views/home/goodsDetail/newestDetail.vue"

export default script