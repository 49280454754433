<template>
    <div class="wrapper" v-if="detail">
        <headBack :showKef='true'>
            <template v-slot:title>
                <div class='title'>
                    服务详情
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <div class="new-box" >
            <div class="header">
                <div class="top">
                    <img :src="detail.bimg1" alt="">
                    <ul>
                        <li><span class="tit">{{detail.title}}</span></li>
                        <li class="sub">{{detail.describe}}</li>
                        <li class="price">￥{{detail.price}}<span class="ci">/次</span></li>
                    </ul>
                </div>
                <div class="sub2">本服务由为北京快天使医护到家护理站您提供服务</div>
            </div>
            <div class="content-one">
                <div class="title">
                    <img src="https://static.yihu365.cn/img/h5Img/assets/img/detail/icon1.png" alt="">
                    <span>服务提示</span>
                </div>
                <ul class="list">
                    <li  v-for="(item,index) in detail.serviceHint" :key="item"><span class="cr"></span><span class="text">{{item}}</span></li>
                   </ul>
            </div>
            <div class="content-one liu-c" v-if="detail.testingProcess">
                <div class="title">
                    <img src="https://static.yihu365.cn/img/h5Img/assets/img/detail/icon1.png" alt="">
                    <span>检测流程</span>
                </div>
                <div class="l-ch">
                    <img :src="detail.testingProcess" alt="">
                </div>
            </div>
        </div>
        <div class="content-one tis" v-if="route.query.serviceType != 2">
            <div class="title">
                <img src="https://static.yihu365.cn/img/h5Img/assets/img/detail/icon1.png" alt="">
                <span>温馨提醒</span>
            </div>
            <ul class="list">
                <li v-for="(item,index) in detail.warmHint" :key="item"><span class="cr"></span><span class="text">{{item}}</span></li>
              </ul>
        </div>
        <div class="content-one p-tai">
            <div class="title">
                <img src="https://static.yihu365.cn/img/h5Img/assets/img/detail/icon1.png" alt="">
                <span>平台优势</span>
            </div>
            <ul>
                <li>
                    <img src="https://static.yihu365.cn/img/h5Img/assets/img/detail/yshi-1.png" alt="">
                    <p>康复专家<br/>专业服务</p>
                </li>
                <li>
                    <img src="https://static.yihu365.cn/img/h5Img/assets/img/detail/yshi-2.png" alt="">
                    <p>医患投保<br/>全程安心</p>
                </li>
                <li>
                    <img src="https://static.yihu365.cn/img/h5Img/assets/img/detail/yshi-3.png" alt="">
                    <p>专业团队<br/>随时售后</p>
                </li>
            </ul>
        </div>
        <div class="content-one tui-y">
            <div class="title">
                <img src="https://static.yihu365.cn/img/h5Img/assets/img/detail/icon1.png" alt="">
                <span>退约或修改</span>
            </div>
            <div class="txt">
                <p class="tit">退款</p>
                <p v-for="(item,index) in detail.refundDescribe" :key="item">{{item}}</p>
               </div>
            <div class="txt">
                <p class="tit">修改</p>
                <p v-for="(item,index) in detail.modification" :key="item">{{item}}</p>
            </div>
        </div>
    </div>
    <div class='btn_btm_cont'>
        <div class='btn_inner'>
            <div class='money'><span class='small'>¥</span>{{detail.price}}<span class='small'>元起</span></div>
            <van-button type="success" color='#78BAEE' class='submit_btn' round @click='gotoSorderPage'>立即预约
            </van-button>
        </div>
    </div>
</template>
<script>
import {setWxShareDate} from '@/libs/utils.js'
import {onMounted, ref, computed, reactive} from "vue"
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'
import {Dialog} from 'vant'
import homeApi from '@axios/home'

export default {
  name: "newestDetail",
  setup() {
    let router = useRouter();
    const route = useRoute();
    const store = useStore();
    const scdSvCode = ref('');
    let detail = ref([]);
    let themeColor = ref('#22CF6A');

    const Recommenduserphone = route.query.Recommenduserphone ?  route.query.Recommenduserphone : '' //护士任务参数 手机号
    const batchNumber = route.query.batchNumber ?  route.query.batchNumber : '' //护士任务参数
    const getDetail = (roleCode, firsvCode, scdsvCode) => {
      let params = {
        // id : id,
        rolecode: roleCode,
        firsvcode: firsvCode,
        scdsvcode: scdsvCode,
        serviceType: route.query.serviceType
      }
      homeApi.goodsDetail(params).then((res) => {
        console.log(res);
        detail.value = res.data.content2;
        scdSvCode.value = res.data.content2.scdsvCode ? res.data.content2.scdsvCode : '';
        themeColor.value = res.data.content2.theme
        setWxShareDate(`医护到家：${res.data.serviceName}`,res.data.headImg)//分享
      })
    }

    getDetail(route.query.rolecode, route.query.firsvcode, route.query.scdsvcode)

    const  gotoSorderPage = () => {
      router.push({
        name:"sorder",
        query:{
          code:detail.value.firsvcode,
          roleCode:detail.value.rolecode,
          scdSvCode:scdSvCode.value,
          price:detail.value.price,
          serviceType:route.query.serviceType,
          Recommenduserphone:Recommenduserphone,
          batchNumber:batchNumber
        }
      })
    }

    // 护士推广绑定用户部分
    const setBindNurse =()=> {
      const params ={
        userId:store.state.uid,
        recommenduserphone:Recommenduserphone
      }
      homeApi.setBindUserToNurse(params).then((res) => {


        if(res.code == '0000'){
          Dialog.alert({
            title:'温馨提示',
            message:'恭喜您，已跟护士绑定成功',
            theme:'round-button',
            confirmButtonColor:'rgb(0, 194, 145)'
          })
        }
        // else{
        //   Dialog.alert({
        //     title:'温馨提示',
        //     message:'很抱歉，您已绑定过护士了',
        //     theme:'round-button',
        //     confirmButtonColor:'rgb(0, 194, 145)'
        //   })
        // }

      })
    }


    if(Recommenduserphone){
      if(store.state.token){//已经登录
        setBindNurse();
      }else{
        Dialog.alert({
          title:'温馨提示',
          message:'尊敬的用户您好，为方便给您提供更好的服务，您可以跟护士做绑定关系，点击立即绑定即可。',
          theme:'round-button',
          confirmButtonText:'立即绑定',
          confirmButtonColor:'rgb(0, 194, 145)'
        }).then(()=> {

          router.push({
            name : 'login',
            query : {
              Recommenduserphone:Recommenduserphone,
              batchNumber:batchNumber

            }
          })
        })
      }

    }

    return {
      detail,
      scdSvCode,
      gotoSorderPage,
      themeColor,
      route
    }

  }
}

</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    * {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .new-box {
        font-size: 28px;
        box-sizing: border-box;
        padding: 32px;

        .header {
            padding: 24px;
            background: #F2FDF8;
            border-radius: 16px;
            margin-bottom: 40px;
            .top {
                display: flex;
                color: #323232;

                img {
                    width: 170px;
                    height:170px;
                    border-radius: 10px;
                    margin-right: 16px;
                }

                .tit {
                    font-weight: 500;
                    font-size: 34px;
                }

                .num {
                    background: #CBF1FA;
                    border-radius: 20px 4px 20px 4px;
                    color: #28B0D1;
                    font-size: 20px;
                    padding: 5px 10px;
                    margin-bottom: 6px;
                    margin-left: 10px;
                }
                .sub {
                    font-size: 24px;
                    font-weight: 400;
                    color: #909399;
                    line-height: 34px;
                    margin: 20px 0;
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                    width:470px;
                }

                .price {
                    color: #E05656;
                    font-weight: bold;
                    font-size: 38px;
                }

                .ci {
                    color: #E05656;
                    font-weight: bold;
                    font-size: 40px;

                }
            }

            .sub2 {
                margin-top: 22px;
                font-size: 24px;
                color: #C8C8C8;
            }
        }

    }

    .content-one {
        .title {
            width: 240px;
            font-size: 40px;
            margin: 24px auto;
            background: #78BAEE;
            color:#ffffff;
            padding:8px 8px 8px 10px;
            border-radius: 44px;
            img{
                width: 48px;
                vertical-align: middle;
                margin-right: 10px;
            }
            span{
                display: inline-block;
                vertical-align: middle;
                 margin-bottom: 4px;
                font-weight: 500;
            }
        }

        .list {
            font-size: 28px;
            color: #606266;
            box-sizing: border-box;

            li {
                margin-left: 1.5em;
                text-indent: -1.08em;
            }

            .text {

            }
        }

        .list > li .cr {
            display: inline-block;
            background: #78BAEE;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            vertical-align: middle;
            margin-right: 20px;
        }

        .l-ch {
            img {
                width: 100%;
            }

        }
    }

    .tis {
        padding: 24px 24px 24px;
        background: #E9F5FF;
        .title{
            margin-top: 0px;
        }
    }
    .liu-c{
        margin-top: 40px;
       .title{
           margin-bottom: 0px!important;
       }
    }
    .p-tai {
        box-sizing: border-box;
        padding: 0 32px 40px;
        font-size: 28px;
        ul {
            display: flex;
            justify-content: space-around;
            li {
                color:#606266;
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                    width: 144px;
                    margin-bottom: 20px;
                }
            }
        }
    }
    .tui-y{
        color: #606266;
        font-size: 28px;
        background: #E9F5FF;
        padding:26px 26px 140px 26px;
        .title{
            margin-top: 0px;
            width:275px;
        }
        .txt{
            p{
                margin-left: 1.3em;
                text-indent: -1.1em;
            }
            .tit{
                font-size: 34px;
                margin-bottom: 16px;
                margin-top: 16px;
            }
        }
    }
    .btn_btm_cont {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 20px 0;
        // display: flex;
        // justify-content: flex-end;
        background: #fff;
        z-index: 999;
        border-top: 1px solid #f5f5f5;

        .btn_inner {
            padding: 0 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .order_btn {
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            height: 50px;
            padding: 0 22px;
            line-height: 50px;
            border-radius: 25px;
            border: 1px solid #999999;
            margin-left: 20px;
        }

        .money {
            margin-right: 40px;
            color: #FF6161;
            font-size: 45px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
        }

        .small {
            font-size: 28px;
            margin: 0 5px;
        }

        .submit_btn {
            width: 200px;
            height: 80px;
            line-height: 80px;
            white-space: nowrap;
        }
    }
</style>
